import styled from 'styled-components';

import { NewButton } from '../../components/Toolkit/NewButton/NewButton';

import { FIRST_BREAK_POINT } from './styles';

export const MainColumn = styled.div`
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    flex-basis: 100%;
  }
`;

export const SideColumnWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    display: block;
    margin-left: 36px;
    flex-shrink: 0;
    width: 160px;
  }
  @media only screen and (min-width: 940px) {
    width: 324px;
  }
`;

export const Result = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.M32};
  margin-left: auto;
  margin-right: auto;
  max-width: 680px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    box-shadow: none;
  }
`;

export const SearchResults = styled.ul`
  list-style: none;
`;

export const StyledButton = styled(NewButton)`
  margin-left: 0px;

  @media only screen and (min-width: 704px) {
    margin-left: ${({ theme }) => theme.spacing.S12};
  }
`;

export const StyledH1Wrapper = styled.div<{
  isMobileRow: boolean;
}>`
  align-items: center;
  display: flex;
  flex-basis: 100%;
`;

// As per design, if we are in the mobile view and we don't have an agent filter, we want the 'map view' button to be on the same row as the SearchH1 text
export const SearchResultsHeaderButtonContainer = styled.div<{
  isMobileRow: boolean;
}>`
  align-items: ${(isMobileRow) => (isMobileRow ? 'center' : 'flex-start')};
  display: flex;
  flex-basis: 100%;
  gap: ${({ theme }) => theme.spacing.S12};
  margin: ${({ isMobileRow, theme }) =>
    isMobileRow ? 0 : `${theme.spacing.M24} 0 0 0`};
  justify-content: ${({ isMobileRow }) =>
    isMobileRow ? 'flex-end' : 'flex-start'};

  @media only screen and (min-width: 704px) {
    align-items: flex-start;
    justify-content: space-between;
    margin: ${({ theme }) => `${theme.spacing.M32} 0 0 0`};
  }
`;

export const FilterButtonsContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.GREY_LIGHTER};
  flex-basis: 100%;
`;

export const SortFilterWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 704px) {
    display: block;
  }
`;

export const ErrorMessageContainer = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;
  vertical-align: middle;
`;

export const MapWrapper = styled.div`
  display: flex;
`;

export const MapSearchContainer = styled.div`
  flex: 1;
`;

export const MobileCarousel = styled.div`
  background: #ffffff;
  height: 110px;
  position: fixed;
  bottom: 0px;
  overflow: scroll;
  width: 100vw;
  scroll-snap-type: x mandatory;
  z-index: 1;
  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export const StyledList = styled.ul`
  display: grid;
  grid-template-columns: repeat(50, 90vw);
  height: 110px;
`;

export const StyledListItem = styled.li<{ noResults: boolean }>`
  background: #ffffff;
  width: 90vw;
  height: 110px;
  scroll-snap-align: start;
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  a {
    box-shadow: none;
  }
  :last-of-type {
    scroll-snap-align: end;
  }
  :only-of-type {
    width: 100vw;
  }
  ${({ noResults }) =>
    noResults &&
    `
  background: #f4f4f4; 
  display: flex;
  align-items: center;
  justify-content: center;
  `}
`;

export const PanelWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M32};
  padding: 0 ${({ theme }) => theme.spacing.M20};

  @media only screen and (min-width: 900px) {
    padding: 0;
  }
`;

export const StyledA = styled.a`
  text-decoration: none;
  display: block;
  color: inherit;

  &:focus,
  &:active {
    color: inherit;
    outline: none;
  }
  &:hover {
    color: inherit;
  }
`;

export const GalleryImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.64);
`;

export const GalleryImageContainerContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
